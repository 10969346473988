<template>
  <f7-page class="login-page">
    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/success.svg" :alt="$t.getTranslation('LBL_CONGRATULATIONS')" loading="lazy" />

        <h1>{{ $t.getTranslation('LBL_CONGRATULATIONS') }}</h1>

        <p v-if="registerInfo?.IsMergeSuccess == 1">{{ $t.getTranslation('LBL_CONGRATULATIONS_MERGE_SUB') }}</p>
        <p v-else>{{ $t.getTranslation('LBL_CONGRATULATIONS_SUB') }}</p>

        <p v-if="registerInfo.OpenId == ''">{{ $t.getTranslation('LBL_WECHAT_NOT_MERGE') }}</p>

        <!-- <f7-button fill large raised @click="onLogin"> {{ $t.getTranslation("LBL_SIGN_IN") }} </f7-button> -->
        <f7-button fill large raised @click="onContinue"> {{ $t.getTranslation('LBL_CONTINUE') }} </f7-button>

        <span v-if="referral.NewUserCoupon" style="margin-top: 35px">{{ $t.getTranslation('LBL_GOT_COUPON_REGISTER') }}</span>
        <!-- <span v-if="referral.ReferralUser" style="margin-top: 35px">{{ $t.getTranslation("LBL_REFERRER") }}: {{ $h.renderUserName(referral.ReferralUser) }}</span> -->
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import { login as authLogin } from '@/logics/auth.js'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'

export default defineComponent({
  name: 'RegisterSuccessPage',
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    let referral = ref(store.getters['referral/getData'])

    const registerInfo = computed(() => store.getters['register/getData'])

    onMounted(() => {
      if (props?.f7route?.query?.merge && props?.f7route?.query?.merge == 1) {
        store.dispatch('register/setData', {
          IsMergeSuccess: 1
        })
      }

      store.dispatch('referral/setData', { NewUserCoupon: false, ReferralCode: null, ReferralUser: null })
    })

    const onLogin = async () => {
      store.dispatch('register/resetData')
      props.f7router.navigate('/login/')
    }

    const onContinue = async () => {
      const registerInfo = store.getters['register/getData']
      helpers.showLoader()

      let isLogin = await authLogin('/mobile/auth/login/new', { MobileCode: registerInfo.MobileCode, MobileNumber: registerInfo.MobileNumber })

      if (isLogin) {
        helpers.hideLoader()

        store.dispatch('register/resetData')
        props.f7router.navigate({ name: 'shopPage' })
        return
      }
    }

    return { onLogin, onContinue, referral, registerInfo }
  }
})
</script>
<style scoped>
.register-container img {
  margin-top: 120px;
}
</style>
